import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/how-to-get-the-best-price-when-selling-your-house-in-san-diego"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How to Get the Best Price When Selling Your House in San Diego.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Are you thinking about selling your San Diego real estate? The market is still
                working in your favor, but there are strategic moves you can make to get the best
                price. From setting the right price to hiring the right realtor, we'll walk you
                through essential strategies to help you attract serious buyers.
              </CommonParagraph>
              <Heading type="h2">Price Competitively</Heading>
              <CommonParagraph>
                Set your price too high, and you’ll scare buyers away (or just make them chuckle in
                disbelief). Set it too low, and you may leave money on the table.
              </CommonParagraph>
              <CommonParagraph>So, how do you find the sweet spot? </CommonParagraph>
              <CommonParagraph>
                Buyers have a psychological tendency to perceive prices just below the round number
                as more attractive than the round number itself. For example, most people would
                agree that a $4.99 bag of apples feels like a better buy than that bag priced at
                $5.00. The same applies to houses.
              </CommonParagraph>
              <CommonParagraph>
                Instead of pricing your San Diego real estate at $700,000, consider lowering the
                price to $699,000 or even $699,500. As a seller, that price difference is
                negligible. But to a buyer, it often looks much larger than it is.
              </CommonParagraph>
              <Heading type="h2">Hire an Experienced Listing Agent</Heading>
              <CommonParagraph>
                Over the last few years, the San Diego real estate industry has seen an influx of
                new agents, many of whom were drawn to the hot market. Unfortunately, many of these
                agents do not have the experience to help clients negotiate a highly competitive
                market.
              </CommonParagraph>
              <CommonParagraph>
                Our team leader,{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-real-estate-agents/joanie-selby"
                  text="Joanie"
                />
                , has been an industry leader for over 20 years, and our realtors,{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-real-estate-agents/john-selby"
                  text="John"
                />{" "}
                and{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-real-estate-agents/cassandra-altmann"
                  text="Cassandra"
                />
                , are both 10-year real estate veterans. Plus, our team knows the San Diego area
                like the back of our hands, which makes us a necessary ally in a highly competitive
                market.
              </CommonParagraph>
              <CommonParagraph>
                As an award-winning company with a consistent 5-star rating across Google, Yelp,
                Facebook, and Zillow, it’s safe to say our success speaks for itself.
              </CommonParagraph>
              <Heading type="h2">Be Open to Constructive Criticism</Heading>
              <CommonParagraph>
                Our agents deeply understand the local market, current trends, and what buyers are
                looking for. We also bring an objective perspective to real estate deals—which means
                we are pretty good at assessing your home's strengths and weaknesses.
              </CommonParagraph>
              <CommonParagraph>
                To get the best price when selling your San Diego home, lean on our expertise and be
                open to our polite yet strategic suggestions. Trust us, we will help you avoid
                costly mistakes and ensure a smooth transaction.
              </CommonParagraph>
              <Heading type="h2">We’ll Take You from “For Sale” to “Sold” – Fast.</Heading>
              <CommonParagraph>
                Are you thinking about selling your San Diego real estate? Then you need an ally—an
                experienced realtor who understands the market and your needs and financial goals.
                When you work with{" "}
                <BlogLink url="https://selbysellssd.com/" text="The Selby Team" />, you can rest
                assured that you’ll sell your home quickly for a price you’ll love.{" "}
                <ContactSlideoutLink text="Contact us" /> today!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
